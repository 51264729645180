<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Ship From(Mill)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="customerModel" :items="customerLists" default="" item-value="CustomerId" :item-text="item => item.CustomerId +' - '+ item.NamaCustomer" label="Enter Customer Name/ID" clearable dense prepend-icon="mdi-account-multiple" @keyup.enter="updateCustomerDropdown()" @change="(event) => emptyCustomer(event)" @mouseenter="(event) => emptyCustomer(event)" id="customer">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Customer Name/ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="orderIdModel" :items="orderIdLists" default="" item-value="CustomerOrderNo" :item-text="item => item.CustomerOrderNo +' - '+ item.NamaCustomer" label="Enter Order ID" clearable dense prepend-icon="mdi-cart" @keyup.enter="updateOrderIdDropdown()" @change="(event) => emptyOrderID(event)" @mouseenter="(event) => emptyOrderID(event)" id="order_id">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Order ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="outstandingModel" :items="outstandingLists" default="" item-value="outstanding_id" item-text="outstanding_text" label="Outstanding Order" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="releaseModel" :items="releaseLists" default="" item-value="release_id" item-text="release_text" label="Release Ship" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

                <div class="col-lg-12 cardResult">
                    <div class="card bgCustom2">
                        <div class="card-body">
                            <h5 class="card-title">Result</h5>
                                <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-25">
                                                <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.showDetails`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip @click="showItemDetails(item)" color="blue" link outlined v-on="on">Details</v-chip>
                                            </template>
                                            <span>Show Detail</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                        </div>
                    </div>
                </div>
            
        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                    </v-data-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Status',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            customerModel: '',
            customerLists: [],
            orderIdModel: '',
            orderIdLists: [],
            outstandingModel: '',
            outstandingLists: [
                { outstanding_id: '', outstanding_text: 'ALL' },
                { outstanding_id: 'Y', outstanding_text: 'YES' },
                { outstanding_id: 'N', outstanding_text: 'NO' }
            ],
            releaseModel: '',
            releaseLists: [
                { release_id: '', release_text: 'ALL' },
                { release_id: 'Y', release_text: 'YES' },
                { release_id: 'N', release_text: 'NO' }
            ],
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            itemLists: [],
            headers: [
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CREATE DATE', value: 'TglPesan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RELEASE', value: 'dt_release', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVE', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ALLOW SHIP.', value: 'ship_flag', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'CustName', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SHIP TO', value: 'DikirimKe', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            searchItem: '',
            loadingDatatable: false,
            itemDetailLists: [],
            headersDetails: [
                { text: 'ORDER NO.', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'Panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'qty_order', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PLAN QTY.', value: 'qty_plan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD QTY.', value: 'qty_prod', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP QTY.', value: 'qty_ship', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RET. QTY.', value: 'qty_ret', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'STAT', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false
        }
    },
    async mounted(){
        
        this.initialize()
        document.querySelector(".cardResult").style.display = "none"

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderStatus`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.$store.dispatch('setOverlay', false)
            })
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderStatus/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    orderid: this.orderIdModel ? this.orderIdModel : '',
                    outstanding: this.outstandingModel ? this.outstandingModel : '',
                    releaseShip: this.releaseModel ? this.releaseModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },

        async showItemDetails(id){

            console.log(id.CustomerOrderNo)

            $('#detailModal').modal('show')
            
            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderStatus/getDataDetails`, { 
                    orderid: id.CustomerOrderNo
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
            })

        },

        updateCustomerDropdown()
        {
            var id = $('#customer').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderStatus/getCustomer?customer=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.customerLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Customer not found, please try again !',
                                icon: 'error',
                            })
                        }
                })
            } else {
                this.customerLists = []
            }
        },

        updateOrderIdDropdown()
        {
            var id = $('#order_id').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderStatus/getOrderID?orderid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.orderIdLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Order ID not found, please try again !',
                                icon: 'error',
                            })
                        }
                })
            } else {
                this.orderIdLists = []
            }

        },

        emptyOrderID(id)
        {
            if(id == null){
                this.orderIdLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null){
                this.customerLists = []
            }
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }   

</style>